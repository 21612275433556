/* eslint-disable */
import React, { Component } from 'react';
import { fpFraudDetect } from '@nmg/auth';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import { Consumer } from '../../PanelContext';
import AccountAccess from '../../../../../profile/components/AccountAccess/AccountAccess';
import {
  entryValidation,
  loginErrorCallBack,
  amplifyLogin,
} from '../../../../../utilities/amplifyUtils';
import { checkIfMFAOptInAvailable, resetMFAOptinAvailability } from '../../../../../utilities/mfaActionUtils';
import { sendUtagLinkData, updateUtagData, sendUtagLinkWithoutUpdatingUtagData} from '../utilities'
import { getSummaryData } from 'client/utilities/loayltyUtils';

class AccountAccessWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      displayError: false,
      loading: false,
      errorMessage: '',
      checked: true,
    };
  }
  componentDidMount() {
    const {   
      VALIDATE_LOGIN
    } = this.props;
   
  
      VALIDATE_LOGIN && fpFraudDetect('lg')
  
  }
  toggleCheck = () => {
    this.setState((state) => ({ checked: !state.checked }));
  }

  setLoginFailureAnalytics = () => {
    sendUtagLinkData({
      logged_in_previous_page_flag: 'false',
      logged_in_status: 'false',
      login_bt_click: 'true',
    });
    updateUtagData({
      login_bt_click: 'false',
    })
  }

  setLoginSuccessAnalytics = (email, checked) => {
    const kmsiSelected = checked? 'true': 'false'

    sendUtagLinkWithoutUpdatingUtagData({
      kmsi_flag: kmsiSelected,
      customer_email: email,
      customer_registered: 'true',
      customer_status: 'Logged in',
      logged_in_previous_page_flag: 'true',
      logged_in_status: 'true',
      login_bt_click: 'true',
    });
    updateUtagData({
      customer_email: email,
      customer_registered: 'true',
      customer_status: 'Logged in',
      logged_in_previous_page_flag: 'false',
      login_bt_click: 'false',
    })

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, checked } = this.state;
    if (!entryValidation(email, password)) {
      this.setLoginFailureAnalytics();
      this.setState({
        displayError: true,
        errorMessage: 'The supplied email address or password is incorrect.',
      });
    } else {
      checked && saveToLocalStorage('_kmsi', true);
      this.userLogin(email, password, checked);
    }
  };

  userLogin = (email, password, checked) => {
    const {
      DT_LOGIN_FLOW,
      UCA_PROFILE_COOKIE,
      PZP_IDENTITY,
      LOCK_AUTHSTATUS_API,
      DISABLE_ATG_LOGIN,
      UCA_MFA,
      EMPLOYEE_STATUS_V2,
      DISCOUNT_ELIGIBILITY_V1,
    } = this.props;
    const errorCb = (error) => {
      this.setLoginFailureAnalytics();
      loginErrorCallBack(error, this, email, LOCK_AUTHSTATUS_API);
    }

    const successCb = async (user = {}) => {
      const { updateNameHeaders, updateShoppingCart, goToPage } = this.props;
      try {
        await getSummaryData(user, updateUtagData);
      } catch (error) {
        console.log(error);
      } finally {
        if(user?.challengeName != 'SMS_MFA') {
          this.setLoginSuccessAnalytics(email, checked);
          updateNameHeaders(user);
          updateShoppingCart();
          window?.minicart?.getMergeCart();
          goToPage('homePage');
        }
      }
      
    };

    const goToMfa = (mfaUser) => {
      this.props.goToPage('mfaPage', { mfaUser });
    }

    this.setState({ loading: true });

    amplifyLogin(
      email,
      password,
      errorCb,
      false,
      successCb,
      DT_LOGIN_FLOW,
      UCA_PROFILE_COOKIE,
      PZP_IDENTITY,
      DISABLE_ATG_LOGIN,
      UCA_MFA,
      goToMfa,
      EMPLOYEE_STATUS_V2,
      DISCOUNT_ELIGIBILITY_V1,
    );
  }

  forgotPasswordClick = () => {
    const utagKey = window.utag_data_dt ? 'event_name' : 'ajax_response_id';
    const utagProperty = utagKey === 'event_name' ? 'pageLoad' : undefined;
    sendUtagLinkData({
      [utagKey]: 'panel_forgot_password'
    });
    updateUtagData({
      [utagKey]: utagProperty,
    })
    this.props.goToPage("forgotPasswordPage");
  }

  render() {
    const {
      loading, checked, displayError, errorMessage,
    } = this.state;
    return (
      <Consumer>
        {() => { // { email, saSelected, pageStack } Consumer args if necessary
          return (
            <>
              <AccountAccess
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                displayError={displayError}
                loading={loading}
                errorMessage={errorMessage}
                checked={checked}
                toggleCheck={this.toggleCheck}
                showForgotPwModal={this.forgotPasswordClick}
                isPanel
                NMO_LOYALTY_NEW={this.props.NMO_LOYALTY_NEW}
                LOY_LANDING={this.props.LOY_LANDING}
              />
            </>
          );
        }}
      </Consumer>
    );
  }
}



export default AccountAccessWrapper;
